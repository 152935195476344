function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime( d.getTime() + (exdays * 24 * 60 * 60 * 1000) );
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split( ';' );
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt( 0 ) == ' ') {
      c = c.substring( 1 );
    }
    if (c.indexOf( name ) == 0) {
      return c.substring( name.length, c.length );
    }
  }
  return "";
}

export default {
  init() {
    const dialog = document.querySelector( ".modal" );

    if (!dialog) {
      return;
    }
    const dialogShownCookieName = 'dialog-shown';
    const dialogClose = dialog.querySelectorAll( "a, button" );

    [...dialogClose].forEach( (button) => {
      button.addEventListener( "click", event => {
        setCookie( dialogShownCookieName, "yes", 1 );
      } );
    } );

    if ("yes" !== getCookie( dialogShownCookieName )) {
      dialog.showModal();
    }
  },
};
