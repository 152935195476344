class ArchiveFilters {
  constructor() {
    this.selectedCategory = 'all';
    this.selectedTeam = 'all';
    this.filteredResults = document.querySelector( '.filtered-results' );
    this.filteredResultsText = this.filteredResults.textContent;
    this.filteredResultsTemplate = this.filteredResultsText.replace(/\d+/g, '{count}');
    this.featuredCardsContainer = document.querySelector( '.archive-case__featured' );

    // Filter click item.
    this.archiveFilters = Array.from( document.querySelectorAll( '[data-toggle-cat]' ) );
    // Add callbacks to the filter options.
    this.archiveFilters.forEach( filter => filter.addEventListener( 'click', e => {
      e.preventDefault();
      this.selectedCategory = e.target?.dataset?.toggleCat;
      this.filter();
    } ) );

    // The extra dropdown filter
    this.dropdownFilters = Array.from( document.querySelectorAll( '.filter-dropdown' ) );
    // Add callback to the selects.
    this.dropdownFilters.forEach( filter => filter.addEventListener( 'change', e => {
      e.preventDefault();
      this.selectedTeam = e.target.querySelector( 'option:checked' )?.value;
      this.filter();
    } ) )

    // All cards/items to be filtered.
    this.allItems = Array.from( document.querySelectorAll( '[data-cat]' ) );

    // All cards that are featured.
    this.featuredCards = Array.from( document.querySelectorAll( '.card-featured' ) );

    // All featured cases cards.
    this.featuredCaseCards = Array.from( document.querySelectorAll( '.archive-case__featured .card-case' ) )

    this.allItemsContainer = document.querySelector( '.archive-case__items .container' )
    this.featuredItemsContainer = document.querySelector( '.archive-case__featured .container' )
  }

  filter() {
    // Set active state of buttons.
    this.toggleFilterActiveState();

    // Filter the cards/items from view.
    this.filterItems();

    document.querySelector( '.archive-team__items .container' )?.classList.toggle( 'show-last-featured', this.isFiltering() );

    this.updateFilteredResultsText();

    if ( this.featuredCardsContainer ) {
      if (this.isFiltering()) {
        this.featuredCardsContainer.classList.add( 'filtering-active' );
      } else {
        this.featuredCardsContainer.classList.remove( 'filtering-active' );
      }
    }
  }

  isFiltering() {
    return this.selectedCategory !== 'all'
  }

  filterItems() {
    // Loop through all the items.
    [...this.allItems, ...this.featuredCards].forEach( item => {
      // Show if 'all' is selected.
      if (this.selectedCategory === 'all' && this.selectedTeam === 'all') {
        item.classList.remove( 'hide' )
        return;
      }

      // Hide if category is not the same.
      if (this.selectedCategory !== 'all' && this.selectedCategory !== item.dataset.cat) {
        item.classList.add( 'hide' );
        return;
      }

      // Hide if team is not the same
      if (this.selectedTeam !== 'all' && this.selectedTeam !== item.dataset.team) {
        item.classList.add( 'hide' );
        return;
      }

      // All checks complete, show it.
      item.classList.remove( 'hide' );
    } );

    // Move the featured case cards
    this.featuredCaseCards.forEach( card => {
      if (card.classList.contains( 'hide' ) || !this.isFiltering()) {
        this.move( this.featuredItemsContainer, card, false )
        return;
      }

      this.move( this.allItemsContainer, card )
    } )
  }

  move(target, element, first = true) {
    target.insertAdjacentElement( first ? 'afterbegin' : 'beforeend', element )
  }

  toggleFilterActiveState() {
    if (!this.selectedCategory) {
      return
    }

    this.archiveFilters.forEach( filter => {
      const isActive = filter.dataset.toggleCat === this.selectedCategory;
      filter.classList.toggle( 'active', isActive );
      filter.setAttribute( 'aria-pressed', isActive.toString() );
    } );
  }

  countVisibleItems() {
    return this.allItems.reduce( (count, item) => {
      return item.classList.contains( 'hide' ) ? count : count + 1;
    }, 0 );
  }

  updateFilteredResultsText() {
    const visibleItemCount = this.countVisibleItems();

    this.filteredResults.textContent = this.filteredResultsTemplate.replace('{count}', visibleItemCount);
  }
}

export default ArchiveFilters;
