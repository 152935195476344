// variables
const skipLink = document.querySelector( '.skip-links a' )

// execution
skipLink.addEventListener( 'keydown', event => {
  if (!event.target.closest( 'a' )) return
  const key = event.key
  if (key !== 'Enter') return
  event.preventDefault()
  const target = event.target.getAttribute( 'href' )
  if (document.querySelector( target )) {
    const targetElement = document.querySelector( target )
    targetElement.setAttribute( 'tabindex', '-1' )
    targetElement.focus()
  } else if (!document.querySelector( target ) && document.querySelector( 'h1' )) {
    const h1 = document.querySelector( 'h1' )
    h1.setAttribute( 'tabindex', '-1' )
    h1.focus()
  } else {
    console.warn( 'SkipLinks are not set, either missing an h1 or main content id on the page.' )
  }
} )
