function stickyExpertCard() {
  const expertCardInHero = document.querySelector( '.hero--expertises [data-sticky]' );
  const expertCardInHeader = document.querySelector( '.expert-card.in-header' );

  if (!expertCardInHero || !expertCardInHeader) {
    return;
  }

  const observer = new IntersectionObserver( entries => {
      entries.forEach( entry => {
        const intersecting = entry.isIntersecting;
        let offset = !intersecting ? 'calc((48px + .5rem) * -1)' : '0';

        expertCardInHeader.classList.toggle( 'show', !intersecting );
      } );
    },
    {threshold: 0}
  );

  observer.observe( expertCardInHero );
}

export default {
  init() {
    stickyExpertCard()
  }
}
