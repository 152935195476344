import SuperGif from 'libgif';

export default function animatedGif() {
    const gifElements = document.querySelectorAll('img.gif');

    gifElements.forEach( ( element, index ) => {
        const autoPlay = !window.matchMedia('(prefers-reduced-motion: reduce)').matches;

        if (element.nodeName === 'IMG') {
            const supergif = new SuperGif({
                gif: element,
                progressbar_height: 0,
                auto_play: autoPlay,
            });

            const controlElement = document.createElement( "button" );
            controlElement.className = `gifcontrol loading g${index}`;
            controlElement.setAttribute( 'aria-label', 'GIF pauzeren' );

            supergif.load(() => {
                controlElement.className = autoPlay ? "gifcontrol playing" : "gifcontrol paused";
                let playing = autoPlay;
                controlElement.addEventListener( "click", () => {
                    if ( playing ) {
                        supergif.pause();
                        playing = false;
                        controlElement.className = "gifcontrol paused";
                        controlElement.setAttribute( 'aria-label', 'GIF afspelen' );
                    } else {
                        supergif.play();
                        playing = true;
                        controlElement.className = "gifcontrol playing";
                        controlElement.setAttribute( 'aria-label', 'GIF pauzeren' );
                    }
                });
            });

            const canvas = supergif.get_canvas();
            // controlElement.style.width = `24px`;
            // controlElement.style.height = `24px`;
            const containerElement = canvas.parentNode;
            containerElement.appendChild(controlElement);
        }
    });
};
