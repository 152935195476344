function focusTrap() {
  const buttonClose = document.querySelector( ".modal__close button" );
  const dialog = document.querySelector( ".modal" );

  if (!dialog) {
    return;
  }

  const focusable =
    'button, a:not(.skiplink), input, select, textarea, [tabindex]:not([tabindex="-1"])';
  const focusableElements = dialog.querySelectorAll( focusable );
  const firstFocusableElement = focusableElements[0];
  const lastFocusableElement = focusableElements[focusableElements.length - 1];

  firstFocusableElement.focus();

  const shutdownFocusTrap = () => {
    dialog.close();
    document.documentElement.style.removeProperty( "overflow" );
    dialog.removeAttribute( "tabindex" );
    document.removeEventListener( 'keydown', handleKeydown );
    buttonClose.removeEventListener( 'click', shutdownFocusTrap );
    buttonClose.focus();
  };

  buttonClose.addEventListener( 'click', shutdownFocusTrap );

  const handleKeydown = (event) => {
    const isEscPressed = (event.key === 'Escape');
    const isTabPressed = (event.key === 'Tab' || event.keyCode === 9);

    if (isEscPressed) {
      shutdownFocusTrap();
    }

    if (!isTabPressed) {
      return;
    }

    if (event.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        event.preventDefault();
        lastFocusableElement.focus();
      }
      return;
    }

    if (document.activeElement === lastFocusableElement) {
      event.preventDefault();
      firstFocusableElement.focus();
    }
  };

  dialog.addEventListener( 'keydown', handleKeydown );

  // Call the focusTrap function when the page loads.
  dialog.setAttribute( "tabindex", "0" );
}

export default {
  init() {
    focusTrap();
  },
};
