function initButtonHovers() {
  const buttons = document.querySelectorAll( '[class*="button"]' );

  if (buttons) {
    [...buttons].forEach( button => {
      button.addEventListener( 'mousemove', event => {
        const rect = button.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;

        button.style.setProperty( '--top', y + 'px' );
        button.style.setProperty( '--left', x + 'px' );
      } );
    } );
  }
}

function initVacanciesHover() {
  const vacancies = document.querySelectorAll( '.vacature' );

  if (vacancies) {
    [...vacancies].forEach( vacancy => {
      vacancy.addEventListener( 'mousemove', event => {
        const rect = vacancy.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;

        vacancy.style.setProperty( '--top', y + 'px' );
        vacancy.style.setProperty( '--left', x + 'px' );
      } );
    } );
  }
}

function initInspireItemHover() {
  const inspireItems = document.querySelectorAll( '.inspire__item' );

  if (inspireItems) {
    [...inspireItems].forEach( inspireItem => {
      inspireItem.addEventListener( 'mousemove', event => {
        const rect = inspireItem.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;

        inspireItem.style.setProperty( '--top', y + 'px' );
        inspireItem.style.setProperty( '--left', x + 'px' );
      } );
    } );
  }
}

export default {
  init() {
    initButtonHovers();
    initVacanciesHover();
    initInspireItemHover();
  },
};
