import Swiper, { Navigation, Pagination, Keyboard, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

Swiper.use([Navigation, Pagination, Keyboard, A11y ]);

function initPeopleSlider() {
  const peopleSwiper = new Swiper( '.people-swiper', {
    slidesPerView: 'auto',
    spaceBetween: 40,
    touchEventsTarget: 'wrapper',
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next'
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true
    },
    a11y: {
      firstSlideMessage: 'Dit is de eerste slide',
      prevSlideMessage: 'Vorige slide',
      nextSlideMessage: 'Volgende slide',
      lastSlideMessage: 'Dit is de laatste slide',
    },
    breakpoints: {
      1200: {
        spaceBetween: 56,
      },
    }
  } );
}

export default {
  init() {
    initPeopleSlider();
  },
};