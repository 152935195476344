function initAcata() {
  const acataTriggers = document.querySelectorAll( '[data-acata]' );

  if (acataTriggers) {
    [...acataTriggers].forEach( acataTrigger => {
      acataTrigger.addEventListener( 'mouseenter', event => {
        event.preventDefault();

        const acataToToggle = acataTrigger.getAttribute( 'data-acata-toggle' );

        if ('' === acataToToggle) {
          console.error( 'No Acata position to toggle, please set data-acata-toggle on this element. Returning now' );
          return;
        }

        resetAcataWrapper( false );
        toggleAllWhiteAcataParts( false );

        try {
          const acataData = JSON.parse( acataTrigger.getAttribute( 'data-acata-data' ) );
          showAcata( acataData.colours, acataData.positionsToTransform, acataData.rotates, acataData.positionsToTranslateTo );
        } catch (e) {
          console.error( 'Could not parse data-acata-data attribute. Not valid JSON.' );
        }
      } );

      acataTrigger.addEventListener( 'mouseleave', event => {
        resetAcataWrapper( true );
        hideAllColouredAcataParts();
        toggleAllWhiteAcataParts( true );
      } );
    } );
  }
}

function resetAcataWrapper(idle) {
  const acataWrapper = document.querySelector( '.acata-wrapper' );
  const reducedMotion = window.matchMedia( '(prefers-reduced-motion: reduce)' ).matches;

  if (!acataWrapper) {
    console.error( 'No acata wrapper present. Returning now.' )
    return;
  }

  // Force reflow to reset animation.
  void acataWrapper.offsetWidth;

  if (idle) {
    acataWrapper.style.translate = '0 0';
    acataWrapper.style.rotate = reducedMotion ? '-90deg' : '0deg';
  }

  acataWrapper.classList.toggle( 'idle', idle );
}

function toggleAllWhiteAcataParts(show) {
  const whiteAcataParts = document.querySelectorAll( '[class*="acata-part-white"]' );

  if (!whiteAcataParts) {
    console.error( 'No white acata parts present. Returning now.' )
    return;
  }

  [...whiteAcataParts].forEach( whitePart => {
    if (show) {
      whitePart.classList.remove( 'hide' );
    } else {
      whitePart.classList.add( 'hide' );
    }
    whitePart.style.rotate = '0deg';
    whitePart.style.translate = '0 0';
  } );
}

function hideAllColouredAcataParts() {
  const colouredAcataParts = document.querySelectorAll( '.acata-coloured-part' );

  if (!colouredAcataParts) {
    console.error( 'No coloured acata parts present. Returning now.' )
    return;
  }

  [...colouredAcataParts].forEach( colouredPart => {
    colouredPart.classList.remove( 'show' );
    colouredPart.style.rotate = '0deg';
    colouredPart.style.translate = '0 0';
  } );
}

function showAcata(colours, positionsToTransform, rotates, positionsToTranslateTo) {
  if (!validateData( colours, positionsToTransform, rotates, positionsToTranslateTo )) {
    console.error( 'Data not valid. Will not continue, returning now.' );
    return;
  }

  const firstElementToShow = document.querySelector( '.acata-part-' + colours.first + '__' + positionsToTransform.first );
  const secondElementToShow = document.querySelector( '.acata-part-' + colours.second + '__' + positionsToTransform.second );
  const positionedFirstElements = document.querySelectorAll( '[class*="acata-' + positionsToTransform.first + '"]' );
  const positionedSecondElements = document.querySelectorAll( '[class*="acata-' + positionsToTransform.second + '"]' );

  if (!firstElementToShow || !secondElementToShow || !positionedFirstElements || !positionedSecondElements) {
    console.error( 'One of the elements is not present. Will not continue, returning now.' );
    return;
  }

  firstElementToShow.classList.add( 'show' );
  secondElementToShow.classList.add( 'show' );

  firstElementToShow.style.rotate = rotates.first + 'deg';
  secondElementToShow.style.rotate = rotates.second + 'deg';

  [...positionedFirstElements].forEach( element => {
    const firstPositionTranslateValues = positionsToTranslateTo.first.split( ',' );

    element.style.rotate = rotates.first + 'deg';
    element.style.translate = firstPositionTranslateValues[0] + ' ' + firstPositionTranslateValues[1];
  } );

  [...positionedSecondElements].forEach( element => {
    const secondPositionTranslateValues = positionsToTranslateTo.second.split( ',' );

    element.style.rotate = rotates.second + 'deg';
    element.style.translate = secondPositionTranslateValues[0] + ' ' + secondPositionTranslateValues[1];
  } );
}

function validateData(colours, positionsToTransform, rotates, positionsToTranslateTo) {
  if (!colours.hasOwnProperty( 'first' ) || !colours.hasOwnProperty( 'second' )) {
    console.error( 'Colours object does not have the property first or second. Returning now.' );
    return false;
  }

  if (!positionsToTransform.hasOwnProperty( 'first' ) || !positionsToTransform.hasOwnProperty( 'second' )) {
    console.error( 'Positions to transform object does not have the property first or second. Returning now.' );
    return false;
  }

  if (!rotates.hasOwnProperty( 'first' ) || !rotates.hasOwnProperty( 'second' )) {
    console.error( 'Rotates object does not have the property first or second. Returning now.' );
    return false;
  }

  if (!positionsToTranslateTo.hasOwnProperty( 'first' ) || !positionsToTranslateTo.hasOwnProperty( 'second' )) {
    console.error( 'Positions to translate to object does not have the property first or second. Returning now.' );
    return false;
  }

  return true;
}

function isTouchDevice() {
  return 'ontouchstart' in window;
}

export default {
  init() {
    if (!isTouchDevice()) {
      initAcata();
    }
  },
};
