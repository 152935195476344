import simpleParallax from 'simple-parallax-js';

function randomIntFromInterval(min, max, floor = true) {
  let randomValue = Math.random() * (max - min + 1) + min;
  return floor ? Math.floor( randomValue ) : randomValue;
}

function initParallaxGallery() {
  const galleryItems = document.getElementsByClassName( 'parallax-gallery__image' );
  let scale = randomIntFromInterval( 2, 3 );

  if (window.innerWidth < 1180) {
    scale = randomIntFromInterval( 1.5, 2 );
  }

  if (!galleryItems) {
    return;
  }

  [...galleryItems].forEach( (galleryItem, index) => {
    new simpleParallax( galleryItem, {
      orientation: 'left',
      overflow: true,
      scale: scale,
      delay: 5
    } );
  } );

  const parallaxGalleries = document.querySelectorAll( '.parallax-gallery.pull-up' );

  if (parallaxGalleries) {
    [...parallaxGalleries].forEach( gallery => {
      const previousElement = gallery.previousElementSibling;
      const nextElement = gallery.nextElementSibling;

      if (previousElement && previousElement.classList.contains( 'content' )) {
        const imagesInPrevBlock = previousElement.querySelectorAll( '.content__image' );

        if (!imagesInPrevBlock) {
          return;
        }

        [...imagesInPrevBlock].forEach( image => {
          new simpleParallax( image, {
            orientation: 'down right',
            overflow: true,
            scale: 1.5,
            delay: 5
          } );
        } );
      }


      if (nextElement && nextElement.classList.contains( 'content' )) {
        const imagesInNextBlock = nextElement.querySelectorAll( '.content__image' );

        if (!imagesInNextBlock) {
          return;
        }

        [...imagesInNextBlock].forEach( image => {
          new simpleParallax( image, {
            orientation: 'down right',
            overflow: true,
            scale: 1.5,
            delay: 5
          } );
        } );
      }
    } );
  }
}

export default {
  init() {
    initParallaxGallery();
  },
};
