function initContentBackgroundSpread() {
  const whiteContentBlocks = document.querySelectorAll( ".content.bg__white" );
  const inViewClass = 'in-view';

  if (!whiteContentBlocks) {
    return;
  }

  const observer = new IntersectionObserver( entries => {
      entries.forEach( entry => {
        const intersecting = entry.isIntersecting;
        const element = entry.target;

        if (intersecting) {
          element.classList.add( inViewClass );
        }
      } );
    },
    {threshold: .5} );

  [...whiteContentBlocks].forEach( whiteContentBlock => {
    observer.observe( whiteContentBlock );
  } );
}

export default {
  init() {
    initContentBackgroundSpread();
  },
};
