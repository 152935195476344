import './components/skip-links'
import Video from "./components/video";
import Navigation from "./components/navigation";
import ExpertCard from "./components/expert-card";
import HoverEffect from "./components/hovereffect";
import parallaxGallery from "./components/parallax-gallery";
import Sliders from "./components/sliders";
import ArchiveFilters from "./components/archive-filters";
import Acata from "./components/acata";
import ContentWidth from "./components/content-width";
import AnimatedGif from "./components/animated-gif";
import Modal from "./components/modal";
import setCookie from "./components/setCookie";

// Block imports in block-directory (Gutenberg), future proofing for HTTP2.
import '../../blocks/code/client';

Video.init();
Navigation.init();
HoverEffect.init();
ExpertCard.init()
Sliders.init();
Acata.init();
ContentWidth.init();
Modal.init();
setCookie.init();

if ( document.querySelector( '.archive-case' ) || document.querySelector( '.archive-team' ) || document.querySelector( '.archive-inspiration' ) ) {
  new ArchiveFilters();
}

AOS.init();

if ( ! window.matchMedia('(prefers-reduced-motion: reduce)').matches ) {
    parallaxGallery.init();
}

AnimatedGif();
