function navigationToggle() {
  const header = document.querySelector( '#header' );
  const logo = header.querySelector( '.header__logo' );
  const menuToggle = header.querySelector( '.menu-toggle' );
  const srOnlyText = menuToggle.querySelector( '.sr-only' );
  const mainMenu = header.querySelector( '#main-menu' );
  const mainMenuRight = header.querySelector( '.main-menu__right' );
  let mainMenuInlineItems = [...header.querySelectorAll( '#menu-hoofdmenu .acato-link' )];
  let prevScrollpos = window.pageYOffset;

  mainMenuInlineItems.push( menuToggle );

  mainMenuInlineItems.forEach( (item) => {
    item.addEventListener( 'keydown', (event) => {
      let targetItem = false;
      const currentIndex = mainMenuInlineItems.indexOf( item );

      if (event.key === 'ArrowRight') {
        if (currentIndex === mainMenuInlineItems.length - 1) {
          targetItem = mainMenuInlineItems[0];
        } else {
          targetItem = mainMenuInlineItems[currentIndex + 1];
        }
      }

      if (event.key === 'ArrowLeft') {
        if (currentIndex === 0) {
          targetItem = mainMenuInlineItems[mainMenuInlineItems.length - 1]
        } else {
          targetItem = mainMenuInlineItems[currentIndex - 1]
        }
      }

      if (currentIndex === mainMenuInlineItems.length - 1 && event.key === 'Tab' && event.shiftKey && !mainMenu.classList.contains( 'active' )) {
        event.preventDefault();
        targetItem = mainMenuInlineItems[currentIndex - 1];
      }

      if (targetItem) {
        targetItem.focus();
      }
    } );
  } );

  const getKeyboardFocusableElements = (element, toggle) => {
    const focusables = [
      ...element.querySelectorAll( 'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])' )
    ].filter( (el) => !el.hasAttribute( 'disabled' ) );

    if (toggle) {
      focusables.unshift( toggle );
    }

    return focusables;
  };

  const trapFocus = (event, element, toggle) => {
    const focusables = getKeyboardFocusableElements( element, toggle )
    const firstFocusable = focusables[0]
    const lastFocusable = focusables[focusables.length - 1]

    if (document.activeElement === lastFocusable && event.key === 'Tab' && !event.shiftKey) {
      event.preventDefault()
      firstFocusable.focus()
    }

    if (document.activeElement === firstFocusable && event.key === 'Tab' && event.shiftKey) {
      event.preventDefault()
      lastFocusable.focus()
    }

  }
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      mainMenu.classList.remove( 'active' );
      document.body.classList.remove( 'locked' );
      menuToggle.setAttribute( 'aria-expanded', 'false' );
      srOnlyText.innerHTML = 'openen';
      menuToggle.focus();
    }

    trapFocus( event, mainMenu, menuToggle )
  }

  if (logo && window.location.pathname === '/') {
    const headerWrapper = document.querySelector( 'header .wrapper' );
    const logoWrapper = document.createElement( 'div' )
    const logoAnnouncement = document.createElement( 'span' )
    const logoContents = logo.innerHTML;

    logo.remove();
    logoWrapper.classList.add( 'header__logo' );
    logoWrapper.innerHTML = logoContents;
    logoAnnouncement.classList.add( 'sr-only' );
    logoAnnouncement.innerHTML = 'Logo Acato Digital Agency';
    logoWrapper.append( logoAnnouncement );
    headerWrapper.prepend( logoWrapper );
  }

  if (mainMenu) {
    menuToggle.addEventListener( 'click', (event) => {
      document.body.classList.toggle( 'locked' );
      mainMenu.classList.toggle( 'active' );

      const openMenu = () => {
        mainMenu.classList.remove( 'not-active' );
        menuToggle.setAttribute( 'aria-expanded', 'true' );
        srOnlyText.innerHTML = 'sluiten';

        document.addEventListener( 'keydown', handleKeyDown )
      }

      const closeMenu = () => {
        mainMenu.classList.add( 'not-active' );
        menuToggle.setAttribute( 'aria-expanded', 'false' );
        srOnlyText.innerHTML = 'openen';

        document.removeEventListener( 'keydown', handleKeyDown )
      }

      mainMenu.classList.contains( 'active' ) ? openMenu() : closeMenu();
    } );
  }

  const navBar = document.querySelector( '#header' );

  window.onscroll = function () {
    if (window.innerWidth > 1200) {
      if (!document.body.classList.contains( 'locked' )) {
        const currentScrollPos = window.pageYOffset;

        if (prevScrollpos > currentScrollPos && !mainMenu.classList.contains( 'active' )) {
          navBar.classList.remove( 'scrolling' );
          document.body.classList.remove( 'header-hidden' );
        } else {
          navBar.classList.add( 'scrolling' );
          document.body.classList.add( 'header-hidden' );
        }

        if (currentScrollPos > 0 && !navBar.classList.contains( 'is-scrolling' )) {
          navBar.classList.add( 'is-scrolling' );
        }

        if (currentScrollPos === 0 && navBar.classList.contains( 'is-scrolling' )) {
          navBar.classList.remove( 'is-scrolling' );
        }

        prevScrollpos = currentScrollPos;
      } else {
        navBar.classList.remove( 'scrolling' );
      }
    }
  }
}

export default {
  init() {
    navigationToggle();
  },
};
