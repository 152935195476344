function iOS() {
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes( navigator.platform ) || (navigator.userAgent.includes( "Mac" ) && "ontouchend" in document)
}

function initVideoObserver() {
  const videoContainers = document.querySelectorAll( '.video-container' );
  const inViewClass = 'in-view';
  const videoData = new Map();

  videoContainers.forEach( (container) => {
    const video = container.querySelector( "iframe[src*='vimeo.com']" );
    const playButton = container.querySelector( '.vimeo-play-button' );
    let vimeoPlayer = new Vimeo.Player( video );
    let isPlaying = false;
    let isUserPaused = false;

    vimeoPlayer.setVolume( 0 );

    videoData.set( video, {playButton, vimeoPlayer, isPlaying, isUserPaused} );

    playButton.addEventListener( 'click', () => {
      let videoInfo = videoData.get( video );
      if (!videoInfo.isPlaying) {
        videoInfo.isPlaying = true;
        videoInfo.isUserPaused = false;
        videoInfo.playButton.setAttribute( "aria-pressed", true );
        videoInfo.vimeoPlayer.play();
        playButton.lastElementChild.setAttribute("hidden", true);
        playButton.firstElementChild.removeAttribute("hidden");
      } else {
        videoInfo.isPlaying = false;
        videoInfo.isUserPaused = true;
        videoInfo.playButton.setAttribute( "aria-pressed", false );
        videoInfo.vimeoPlayer.pause();
        playButton.firstElementChild.setAttribute("hidden", true);
        playButton.lastElementChild.removeAttribute("hidden");
      }
      videoData.set( video, videoInfo );
    } );
  } );

  const observer = new IntersectionObserver( entries => {
    entries.forEach( entry => {
      const intersecting = entry.isIntersecting;
      const video = entry.target;
      let videoInfo = videoData.get( video );

      if (intersecting) {
        video.classList.add( inViewClass );
        if (!videoInfo.isUserPaused && !window.matchMedia( '(prefers-reduced-motion: reduce)' ).matches) {
          videoInfo.isPlaying = true;
          videoInfo.vimeoPlayer.play();
          videoInfo.playButton.lastElementChild.setAttribute("hidden", true);
          videoInfo.playButton.firstElementChild.removeAttribute("hidden");
        }
      } else {
        if (videoInfo.isPlaying && !videoInfo.isUserPaused && !iOS()) {
          videoInfo.isPlaying = false;
          videoInfo.vimeoPlayer.pause();
          videoInfo.playButton.firstElementChild.setAttribute("hidden", true);
          videoInfo.playButton.lastElementChild.removeAttribute("hidden");
        }
      }

      videoData.set( video, videoInfo );
    } );
  }, {threshold: .4} );

  videoContainers.forEach( container => {
    const video = container.querySelector( "iframe[src*='vimeo.com']" );
    observer.observe( video );
  } );
}

export default {
  init() {
    initVideoObserver();
  },
};
